import { AppState } from "~/app-state"

export default () => {
    const state = useState<number>(AppState.SearchLocation, () => 0)

    const changed = () => {
        console.log('changed')
        ++state.value
    }

    return {
        state,
        changed
    }
}